/* v8 ignore start */
import * as Sentry from "@sentry/sveltekit";
import { BROWSER } from "esm-env";

const { PUBLIC_ENVIRONMENT } = import.meta.env;

export const sentry = PUBLIC_ENVIRONMENT !== "production" ? undefined : Sentry;
export const SENTRY_CONFIG = {
  integrations: BROWSER ? [Sentry.replayIntegration()] : [],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
};
/* v8 ignore end */
